import { createStore } from "vuex";

export default createStore({
  state: {
    startLoader: false,
    showSANav: true,
    activePage: null,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
