<template>
  <div id="home">
    <div class="mi-loader" v-if="terms">
      <div class="bg"></div>
      <div class="terms">
        <p>
          By Continuing you agree to our
          <a href="#" @click.prevent="termModal = true">Terms & Conditions</a>
          and
          <a
            href="#"
            @click.prevent="
              termModal = true;
              activeTab = 'privacy';
            "
            >Privacy Policy</a
          >
        </p>
        <button @click="terms = false">Agree and continue</button>
        <div class="img">
          <label for="">Powered By</label>
          <img :src="$img('mediaw.png')" alt="Logo" />
        </div>
      </div>
    </div>
    <div class="header">
      <div class="left">
        <img :src="$img('chelsea.png')" alt="Logo" />
      </div>
      <div class="right">
        <img :src="$img('nike.png')" alt="Logo" />
        <img :src="$img('bingx.png')" alt="Logo" />
      </div>
    </div>
    <div class="home-body">
      <div class="slider-carou">
        <carousel
          :items-to-show="1"
          class="slider-container"
          v-model="currentIndex"
        >
          <slide
            v-for="(slide, index) in slides"
            :key="index"
            class="slide-item"
            :id="'slide' + index"
          >
            <img
              v-if="slide.type === 'image'"
              :src="$img(slide.src)"
              :alt="slide.alt"
            />
            <video v-if="slide.type === 'video'" playsinline muted autoplay>
              <source :src="$img(slide.src)" type="video/mp4" />
            </video>
          </slide>
          <slide class="slide-item" :id="'slide' + slides.length">
            <img :src="$img('sta5.jpg')" />
            <div class="slideLoader">
              <p>Connecting you to Stamford Bridge stadium</p>
              <div class="loader">
                <img :src="$img('load.gif')" />
              </div>
            </div>
          </slide>
        </carousel>
        <div class="slide-footer" v-if="currentIndex < slides.length">
          <div class="menu">
            <i class="fas fa-bars" @click="termModal = true"></i>
            <div
              class="sounds"
              v-if="
                currentIndex < slides.length &&
                slides[currentIndex].type === 'video'
              "
            >
              <i
                class="fa-solid fa-volume-xmark"
                v-if="muted"
                @click="toggleSound()"
              ></i>
              <i
                class="fa-solid fa-volume-high"
                v-else
                @click="toggleSound()"
              ></i>
            </div>
          </div>
          <div class="skip">
            <button @click="nextSlide" id="skipbtn">CLICK TO SKIP</button>
          </div>
        </div>
      </div>
      <div class="slide-buttons">
        <button class="slider-btn prev-btn" @click="prevSlide">&#10094;</button>
        <button class="slider-btn next-btn" @click="nextSlide">&#10095;</button>
      </div>
    </div>
    <div class="terms-modal" v-if="termModal">
      <div class="modal-dialog">
        <div class="dialog-title">
          <h2>Terms & Conditions</h2>
          <button @click="termModal = false">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="tab-links">
          <button
            :class="activeTab == 'eula' ? 'active' : ''"
            @click="activeTab = 'eula'"
          >
            EULA
          </button>
          <button
            :class="activeTab == 'privacy' ? 'active' : ''"
            @click="activeTab = 'privacy'"
          >
            Privacy Policy
          </button>
        </div>
        <div class="tab-content">
          <div class="tab-content-item" v-if="activeTab == 'eula'">
            <div class="mb-2 text-lg font-semibold">Important notice</div>
            <p>
              This end-user licence agreement (<strong>EULA</strong>) and the
              Privacy Policy (<strong>Privacy Policy</strong>) (together the
              <strong>Terms and Conditions</strong>) constitute the legal
              agreement between you (<strong>You</strong> or
              <strong>Your</strong>) and Venue Logic Ltd (<strong>We</strong>,
              <strong>Us</strong> or <strong>Our</strong>) relating to the
              provision by Us to You of a wireless internet access service (the
              <strong>Service</strong>) to be used by You at any venue where the
              Service is available. Please read this EULA and the Privacy Policy
              carefully. If You do not accept the Terms and Conditions We will
              not permit access to the Service and the joining process will
              immediately terminate. By joining and using the Service You agree
              to be legally bound by the Terms and Conditions.
            </p>

            <div class="mb-2 text-lg font-semibold">Agreed terms</div>
            <ol>
              <li class="list-item mb-6 list-decimal list-inside">
                <div class="inline-block mb-2 font-semibold">
                  Acknowledgements and consents
                </div>
                <ol class="list-[lower-roman] list-inside">
                  <li>
                    You acknowledge that the Terms and Conditions (as may be
                    amended from time to time) apply to the use of the Service
                    (including any future updates to it) by You.
                  </li>
                  <li>
                    You acknowledge and accept that We may change the Terms and
                    Conditions at any time. All changes (if any) will be
                    communicated to You through a link on the log-in page for
                    the Service. You will be required to read and accept any
                    such changes to continue using the Service.
                  </li>
                  <li>
                    You must have obtained the permission of the owner of any
                    computer, tablet, mobile telephone, smart phone or other
                    electronic device (Device) which You use to access the
                    Service (and which is not owned by You) to use the Service.
                    By accepting the Terms and Conditions You accept
                    responsibility for the use of the Service on any Device
                    whether or not you own it. You accept that You may be
                    charged by Your service provider for internet access through
                    the Device.
                  </li>
                  <li>
                    You acknowledge and agree that internet transmissions are
                    never completely private or secure and that any information
                    which You send by using the Service may be read or
                    intercepted by another party even if there is a special
                    notice that a particular transmission is encrypted.
                  </li>
                  <li>
                    You acknowledge that the internet is separate from the
                    Service and that websites accessed by You via the Service
                    are not under the control of Us and that We are not
                    responsible for and do not endorse their content or privacy
                    policies (if any). You undertake that You will use Your own
                    judgement regarding any interaction with any such website
                    including the purchase of any products or services
                    accessible through them.
                  </li>
                  <li>
                    You acknowledge and agree that all intellectual property
                    rights in the Service (and its underlying technology) belong
                    to Us (or Our licensors) and that You have no rights in or
                    to the Service other than the non-exclusive and
                    non-transferrable right to use it in accordance with the
                    Terms and Conditions.
                  </li>
                </ol>
              </li>
              <li class="list-item mb-6 list-decimal list-inside">
                <div class="inline-block mb-2 font-semibold">
                  Provision of the Service
                </div>
                <p>
                  In consideration of You accepting the Terms and Conditions, We
                  agree to provide the Service to You until such time as We
                  cease provision of the Service to You. You may stop using and
                  We may stop providing the Service at any time.
                </p>
              </li>
              <li class="list-item mb-6 list-decimal list-inside">
                <div class="inline-block mb-2 font-semibold">
                  Acceptable use
                </div>
                <p>
                  You agree that You will use the Service in accordance with the
                  Terms and Conditions and that You will not (and will procure
                  that any person using Your Device does not) use the Service
                  for any commercial, unlawful, immoral or malicious purpose,
                  nor will You (nor any person using Your Device) in the course
                  of using the Service:
                </p>
                <ol class="list-[lower-roman] list-inside">
                  <li>
                    introduce any code, virus or data which is harmful to the
                    Service or any operating system; or
                  </li>
                  <li>
                    transmit, store, publish or upload any electronic material
                    which is likely to cause damage or limit the functionality
                    of any telecommunications equipment or any computer software
                    or hardware; or
                  </li>
                  <li>
                    infringe any intellectual property right belonging to Us or
                    any other party; or
                  </li>
                  <li>
                    send, receive, publish, distribute, transmit, upload or
                    download any material which is offensive, abusive,
                    defamatory, indecent, obscene, unlawful; or otherwise
                    objectionable; or
                  </li>
                  <li>
                    invade the privacy of or cause annoyance or anxiety to or
                    send any unsolicited correspondence to any other person.
                  </li>
                </ol>
              </li>
              <li class="list-item mb-6 list-decimal list-inside">
                <div class="inline-block mb-2 font-semibold">
                  Limitations and exclusions
                </div>
                <ol class="list-[lower-roman] list-inside">
                  <li>
                    The Service is provided to You on an 'as is' and 'as
                    available' basis and therefore all warranties, conditions
                    and other terms implied by statute or common law are, to the
                    fullest extent permitted by law, excluded from the Terms and
                    Conditions.
                  </li>
                  <li>
                    Save as provided in clause 4(iv), neither We, nor any of Our
                    resellers, shall be liable to You for any losses (whether
                    direct, indirect or consequential) caused by failures,
                    errors, delays or interruptions of, in or to the Service.
                  </li>
                  <li>
                    Save as provided in clause 4(iv), neither We, nor any of Our
                    resellers, shall have any liability to You if any third
                    party gains access to Your connection to the Service or Your
                    Device or destroys or damages any data or information held
                    by You or information about You which is held by Us.
                  </li>
                  <li>
                    Nothing in the Terms and Conditions shall limit or exclude
                    Our liability for (a) death or personal injury resulting
                    from Our negligence, (b) fraud or fraudulent
                    misrepresentation or (c) any other liability that cannot be
                    excluded by law.
                  </li>
                </ol>
              </li>
              <li class="list-item list-decimal list-inside">
                <div class="inline-block mb-2 font-semibold">General</div>
                <ol class="list-[lower-roman] list-inside">
                  <li>
                    The Terms and Conditions constitute the entire agreement
                    between You and Us as to the provision of the Service and
                    they (and any dispute or claims arising from them) are
                    irrespective of the location from which the Service is
                    accessed, governed by and shall be construed in accordance
                    with the laws of Scotland with the Scottish courts having
                    exclusive jurisdiction to settle any dispute or claim
                    arising out of or in connection with them.
                  </li>
                  <li>
                    Any notice to be given by Us to You may be given by post or
                    email to any postal or email address given by You. Any
                    notice to be given by You to Us may be given by email to
                    info@incaptiv.com.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          <div class="tab-content-item" v-if="activeTab == 'privacy'">
            <p>
              Venue Logic Ltd (<strong>We</strong>, <strong>Us</strong> or
              <strong>Our</strong>) know that you take the privacy of your
              information and how it used and shared very seriously and We will
              only use your personal information in accordance with the current
              data protection laws in your country and this privacy policy. This
              privacy policy describes how we aim to repay the trust you have
              shown by sharing your personal data with Us.
            </p>
            <p>
              This policy applies to all the websites We operate, Our use of
              emails and any other methods We use for collecting information. It
              covers what We collect and why, what We do with the information,
              what We won't do with the information, and what rights you have.
            </p>
            <p>
              This privacy policy only applies to personal information We hold
              about individuals. It does not apply to information We hold about
              companies and other organisations.
            </p>
            <p>We may update this privacy policy from time to time.</p>

            <div class="flex flex-col gap-3">
              <div class="text-lg font-semibold">Who We are</div>
              <p>
                In this policy, whenever you see the words (<strong>We</strong>,
                <strong>Us</strong> or <strong>Our</strong>), it refers to Venue
                Logic Ltd. Registered in Scotland Company No: SC746859.
              </p>
            </div>
            <div class="flex flex-col gap-3">
              <div class="text-lg font-semibold">Information We collect</div>
              <p>
                We will only ever collect the information We need - including
                data that will be useful to help improve Our services.
              </p>
              <p>
                We may automatically collect and process the following data
                about you:
              </p>
              <ol class="ml-5 list-decimal list-inside">
                <li>
                  any correspondence between you and Us, your responses to any
                  surveys that We ask you to complete for research purposes,
                  details of any transactions which you carry out through the
                  Service, your browsing history and details of your use of the
                  Service including (without limitation) location data, weblogs
                  and other communication data and resources accessed by you
                  (Additional Information);
                </li>
                <li>
                  any information which We collect about the computer, tablet,
                  mobile telephone, smart phone or other electronic device used
                  to access the service (Device) including (where available) MAC
                  address of any connected network interface, the unique device
                  identifiers, operating system, browser types and telephone
                  number (if applicable) of the Device (Device Information);
                </li>
                <li>
                  any information which We collect about your actual location
                  and relative signal strength, and data collected by Us for
                  Wifi enabled Devices present at a location by means of
                  relative signal strength from Wifi access points from which an
                  X/Y coordinate is calculated (Location Information);
                </li>
                <li>
                  any data collected by Us when Your device is used to access
                  the Service including (without limitation) internet protocol
                  addresses, internet service providers, browser types,
                  language, viewed and exit pages and date and time stamps (Log
                  Information);
                </li>
                <li>
                  details of your visits to Our site including but not limited
                  to, IP addresses (the location of the computer on the
                  internet), pages accessed and files downloaded. This helps Us
                  to determine how many people use Our sites, how many people
                  visit on a regular basis, and how popular Our pages are. This
                  is statistical data about Our users' browsing actions and
                  patterns, and does not identify any individual. It simply
                  allows Us to monitor and improve Our service.
                </li>
              </ol>
              <p>
                Personal information, such as name, postal address, phone
                number, email address, date of birth and gender that you provide
                by when using Our Wifi services and when you contact Us via
                telephone and includes information provided when you:
              </p>
              <ul class="ml-5 list-disc list-inside">
                <li>subscribe to receive one of Our e-communications;</li>
                <li>enquire about a specific product or service;</li>
                <li>request information via telephone;</li>
                <li>request a call back;</li>
                <li>respond to a campaign;</li>
                <li>enter a competition or promotion;</li>
                <li>submit a question to Us or provide Us with feedback.</li>
              </ul>
              <p>
                You are provided with an opportunity to opt out from receiving
                communications from Us or Our partners at the time that your
                details are submitted and on each occasion that you are
                contacted thereafter.
              </p>
              <p>
                Our site uses cookies to distinguish you from other users of Our
                site. This helps Us to provide you with a good experience when
                you browse our site and allows Us to improve Our site. For
                detailed information on the cookies We use and the purpose for
                which We use, please see Our cookie policy. If you want to
                disable cookies please refer to your browser help.
              </p>
            </div>
            <div class="flex flex-col gap-3">
              <div class="text-lg font-semibold">
                How We use the information We collect
              </div>
              <p>
                If you have provided Us with personal data to access Wifi in a
                venue then We will use this data for the performance of the
                contract described in Our terms and conditions of service which
                you agreed to when accessing the service.
              </p>
              <p>
                We may also process your personal data for Our legitimate
                business interests. “Legitimate Interests” means the interests
                of Our company in conducting and managing Our business and
                providing you with the best services and products in the most
                secure way. These interests include:
              </p>
              <ul class="ml-5 list-disc list-inside">
                <li>
                  To ensure that content from Our service is presented in the
                  most effective manner for you and for your device.
                </li>
                <li>
                  To collate and aggregate anonymised information for consumer
                  analysis and statistical reporting.
                </li>
                <li>
                  To associate Submitted Information and/or Additional
                  Information and/or Device Information and/or Location
                  Information and/or Log Information to enable analysis by Us
                  and the owner or sponsor of any venue at which You use the
                  Service as to how people move around such venues – in such
                  circumstances.
                </li>
                <li>
                  To contact you regarding your opinions on Our products and
                  services which may be used for research and analysis to help
                  Us to improve or modify those products and services.
                </li>
                <li>To help Us identify you when you contact or visit Us.</li>
                <li>When dealing with your inquiries and requests.</li>
                <li>
                  To help Us improve the quality, security and safety of Our
                  products and services
                </li>
                <li>To carry out analysis and customer profiling.</li>
                <li>
                  When you communicate with Us for customer service or other
                  purposes (e.g., by emails, faxes, phone calls, tweets, etc.),
                  We retain such information and our responses to you in the
                  records of your account.
                </li>
              </ul>
              <p>
                When We process your personal data for Our legitimate business
                interests We always ensure that We consider and balance any
                potential impact on you and your rights under data protection
                laws.
              </p>
              <p>
                If you have any concerns about the processing described above,
                you have the right to object to this processing. For more
                information on your rights please see “Your Rights” section
                below.
              </p>
            </div>
            <div class="flex flex-col gap-3">
              <div class="text-lg font-semibold">
                Choice / opt-in and opt-out
              </div>
              <p>
                Whenever the processing of your personal data requires your
                consent then you will be given the opportunity to opt-in or
                opt-out to having your contact details used as set out above, at
                the time your details are submitted.
              </p>
              <p>
                For example, when you request product information, you can tell
                Us when you provide your details if you do not want to receive
                any other information from Us or Our partners, or you can let Us
                know how best to get in touch with you with information that may
                be of interest.
              </p>
              <p>
                If you do not wish Us to use your data as set out above, please
                leave the relevant boxes, situated on the form which We used to
                collect your data, blank/unticked.
              </p>
              <p>
                Please be aware that if you opt out of marketing communications
                received from one of our partners, and your information is also
                held by other partners, you may continue to receive information
                from these other partners until you inform them that you wish to
                opt out of marketing communications as well.
              </p>
            </div>
            <div class="flex flex-col gap-3">
              <div class="text-lg font-semibold">Sharing your information</div>
              <p>
                Just like most other organisations, We work with third-party
                service providers which provide important functions to Us that
                allow Us to be easier, faster, and friendlier in the way We
                deliver Our services. We need to disclose user data to them from
                time to time, for any of the purposes set out above, so that the
                services can be performed.
              </p>
              <p>
                We have listed below, those parties to whom We may disclose your
                personal data for the purposes set out in this policy:
              </p>
              <ul class="ml-5 list-disc list-inside">
                <li>Our employees;</li>
                <li>
                  the owners of the venues where you access Our wifi services;
                </li>
                <li>
                  Virtual hosting infrastructure providers to host our servers
                  and data, and to provide other services to Us.
                </li>
                <li>
                  third party consultants, service providers or contractors when
                  providing support and other services to Us;
                </li>
                <li>
                  auditors or advisers assisting Us in Our business operations
                  in any jurisdiction where we operate.
                </li>
              </ul>
              <p>
                We may also disclose your personal information to third parties,
                if We are under a duty to disclose or share your personal data
                for legal or regulatory purposes, in relation to existing or
                future legal proceedings, for the prevention of fraud/loss or to
                protect the rights, property, safety of Our Group, Our customers
                or others.
              </p>
            </div>
            <div class="flex flex-col gap-3">
              <div class="text-lg font-semibold">Data security</div>
              <p>
                Our standard policy is to store your personal Information on
                hosted services located in London. However, from time to time,
                We may allow individual customers to store your personal
                information on their own servers located within their own
                country when they are legally obligated to do so or when they
                request this. We may transfer the information to other offices
                and to other reputable third-party organisations as explained
                above - they may be situated inside or outside the European
                Economic Area, if this is the case We ensure that compliant
                safeguards are put in place and clearly communicated to you. We
                may also store information in paper files.
              </p>
              <p>
                We have security protocols and policies in place to manage and
                record your data privacy and preferences correctly and that your
                data is stored securely to protect against its loss, misuse and
                alteration. Documentation can be supplied on request from Our
                Data Protection Officer using any of the contact methods listed
                below In Your rights.
              </p>
              <p>
                Unfortunately, the transmission of data across the internet is
                not completely secure and whilst We do Our best to try to
                protect the security of your information We cannot ensure or
                guarantee that loss, misuse or alteration of data will not occur
                whilst data is being transferred.
              </p>
              <p>
                We will keep your information only for as long as We need it to
                provide you with the services or information you have required,
                to administer your relationship with Us, to comply with the law,
                or to ensure We do not communicate with people that have asked
                Us not to. When We no longer need information, We will always
                dispose of it securely, using specialist companies if necessary
                to do this work for Us.
              </p>
            </div>
            <div class="flex flex-col gap-3">
              <div class="text-lg font-semibold">Your rights</div>
              <p>
                Under the data protection legislation, You have the right to
                request copies of your data, request rectification of your data,
                request erasure of your data, object to Us processing your data,
                the right to prevent your data being used for direct marketing,
                request Us to restrict the processing of your data and where Our
                systems allow, the right to access a copy of the information We
                hold about you (a subject access request) and the right to lodge
                a complaint with the relevant data protection authority. We will
                require proof of your identity before we are able to provide you
                with any personal information that we hold about you.
              </p>
              <p>
                The following information provides more detail about some of
                these rights and how to exercise them:
              </p>
              <ul class="ml-5 list-disc list-inside">
                <li>Right to make a Subject Access Request (“SAR”)</li>
              </ul>
              <p>
                You have the right to view and request copies of your personal
                data that We hold about you. You are able to view or delete this
                information through Our portal, login details are emailed to you
                when you first register onto our wifi services. You may also
                request this information by contacting Us via one of the methods
                described below.
              </p>
              <ul class="ml-5 list-disc list-inside">
                <li>Right to Rectification</li>
              </ul>
              <p>
                You can request that we rectify inaccuracies and missing
                information in personal data held about you.
              </p>
              <ul class="ml-5 list-disc list-inside">
                <li>Right to Object to Processing</li>
              </ul>
              <p>
                You may have the right to object to Us processing your personal
                data and request that We stop processing it. You may do this by
                emailing Our Data Protection Officer (see below for details).
              </p>
              <ul class="ml-5 list-disc list-inside">
                <li>Right to Request Erasure</li>
              </ul>
              <p>
                You have the right to have your personal data erased where the
                data is no longer necessary for the purpose(s) for which it was
                originally collected/processed unless we have legitimate grounds
                or a legal right(s)/obligation(s) to store your personal data.
              </p>
              <p>
                If you wish to exercise any of these rights please contact the
                Data Protection Officer in writing by emailing
                info@incaptiv.com.
              </p>
              <p>
                For more information about your rights contact your local
                supervisory authority directly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      terms: true,
      slides: [
        { type: "image", src: "sta1.png", alt: "Image 1" },
        { type: "image", src: "sta2.png", alt: "Image 2" },
        { type: "image", src: "sta3.jpg" },
        { type: "video", src: "sta4.mp4" },
      ],
      currentIndex: 0,
      muted: true,
      termModal: false,
      activeTab: "eula",
    };
  },
  methods: {
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    nextSlide() {
      if (this.currentIndex < this.slides.length) {
        this.currentIndex++;
      }
    },
    playVideo(video) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Video playback started successfully
          })
          .catch((error) => {
            // Handle the error, perhaps show a play button to the user
            console.error("Video playback failed:", error);
            // Show a play button overlay on the video
            // video.setAttribute("controls", "true");
          });
      }
    },
    toggleSound() {
      let videos = document.querySelectorAll("video");
      videos.forEach((video) => {
        video.muted = !this.muted;
      });
      this.muted = !this.muted;
    },
  },

  watch: {
    currentIndex() {
      if (this.currentIndex >= this.slides.length) {
        let videos = document.querySelectorAll("video");
        videos.forEach((video) => {
          video.currentTime = 0;
          video.pause();
        });
        setTimeout(() => {
          window.location.href = "https://www.chelseafc.com/en";
          return;
        }, 3000);
      } else {
        const slide = document.querySelector("#slide" + this.currentIndex);
        const video = slide ? slide.querySelector("video") : null;

        if (this.slides[this.currentIndex].type === "video" && video) {
          video.removeAttribute("controls");
          video.currentTime = 0;
          this.playVideo(video);
        } else {
          let videos = document.querySelectorAll("video");
          videos.forEach((video) => {
            video.currentTime = 0;
            video.pause();
          });
        }
      }
    },
  },
  mounted() {
    document.title = "Chealsea FC";
    let videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      video.removeAttribute("controls");
      video.currentTime = 0;
    });
  },
};
</script>

<style scoped lang="scss">
.mi-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 70px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9;
  overflow: hidden;
  .bg {
    background-color: #00000059;
    backdrop-filter: blur(2px);
    filter: blur(2px);
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh - 70px);
    width: 100vw;
    z-index: 9;
  }
  .terms {
    z-index: 9;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    p {
      color: white;
      text-align: center;
      margin-bottom: 1rem;
      font-size: 1.1rem;
      line-height: 1.5;
    }
    a {
      color: white;
      text-decoration: underline;
      cursor: pointer;
    }
    button {
      background: rgba(37, 99, 235);
      color: white;
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 500px;
      margin-top: 1rem;
      font-weight: 600;
      cursor: pointer;
    }
    .img {
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      label {
        color: rgba(255, 255, 255, 0.808);
        font-size: 0.7rem;
        text-transform: uppercase;
        font-weight: 400;
      }
      img {
        width: 150px;
        margin-bottom: 1rem;
      }
    }
  }
}

#home {
  background: #1d1d1d;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 100%;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 1rem;
    height: 100px;
    border-top: 1px solid rgba(255, 255, 255, 0.26);
    margin-top: 40px;
    @media (max-width: 768px) {
      display: none;
    }
    .left img {
      width: 150px;
      // margin-top: -50px;
      margin-left: 100px;
    }
    .right {
      display: flex;
      align-items: flex-start;
      column-gap: 1rem;
      margin-right: 100px;
      margin-top: 10px;
    }
    .right img {
      width: 70px;
    }
  }
  .home-body {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 3rem;
    overflow: hidden;
    @media (max-width: 768px) {
      padding-top: 0rem;
      height: 100%;
    }
    .slider-carou {
      position: relative;
      .slider-container {
        position: relative;
        width: 350px;
        height: 550px;
        border-radius: 10px;
        overflow: hidden;
        @media (max-width: 768px) {
          width: 100%;
          height: calc(100vh - 70px);
        }
        .slide-item {
          width: 100%;
          height: 550px;
          @media (max-width: 768px) {
            height: calc(100vh - 70px);
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media (max-width: 768px) {
              height: calc(100vh - 70px);
            }
          }
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media (max-width: 768px) {
              height: calc(100vh - 70px);
            }
          }
        }
        .slideLoader {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.5);
          p {
            color: white;
            font-size: 1.1rem;
            font-weight: 500;
            text-align: center;
            margin-bottom: 1rem;
            width: 80%;
            line-height: 1.5;
          }
          .loader {
            width: 50px;
            height: 50px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .slide-footer {
        z-index: 9;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.603) 0%,
          rgba(0, 0, 0, 0.466) 50%,
          rgba(0, 0, 0, 0) 100%
        );
        padding: 5px 10px;
        .menu {
          padding: 0.5rem 1rem;
          background: none;
          color: white;
          cursor: pointer;
          display: flex;
          align-items: center;
          i {
            font-size: 1.2rem;
            cursor: pointer;
          }
          .sounds {
            margin-left: 10px;
          }
        }
        .skip button {
          padding: 0.5rem 1rem;
          background: none;
          border: none;
          color: rgba(255, 255, 255, 0.788);
          cursor: pointer;
          font-size: 0.75rem;
          @media (max-width: 768px) {
            font-size: 0.9rem;
            font-weight: 500;
          }
        }
      }
    }
    .slide-buttons {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        display: none;
      }
      .slider-btn {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 0.5rem;
      }
    }
  }
}
.terms-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-dialog {
    background: white;
    padding: 1rem;
    max-width: 1200px;
    width: 100%;
    max-height: 80vh;
    overflow-y: hidden;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    @media (max-width: 768px) {
      height: 100vh;
    }
    .dialog-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 1.25rem;
        font-weight: 600;
      }
      button {
        background: none;
        border: none;
        cursor: pointer;
        i {
          font-size: 1.5rem;
          color: black;
        }
      }
    }
    .tab-links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 1rem;
      button {
        padding: 0.5rem 1rem;
        background: none;
        border: none;
        cursor: pointer;
        color: #333;
        font-weight: 600;
        border-bottom: 1px solid #c4c4c4;
        font-size: 14px;
        &.active {
          border-bottom: 2px solid #00aaf8;
          color: #00aaf8;
        }
      }
    }
    .tab-content {
      margin-top: 1rem;
      .tab-content-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: calc(100vh - 200px);
        overflow-y: auto;
        padding-bottom: 10rem;
        p {
          font-size: 0.9rem;
          line-height: 1.5;
          text-align: left;
          margin-bottom: 1rem;
        }
        ol {
          margin-top: 1rem;
          counter-reset: list;
          margin-left: 15px;
          li {
            counter-increment: list;
            margin-bottom: 1rem;
            font-size: 0.9rem;
            line-height: 1.5;
            text-align: left;
            ol {
              margin-top: 1rem;
              list-style: none;
              counter-reset: list;
              margin-left: -15px !important;
              li {
                counter-increment: list;
                margin-bottom: 1rem;
                &::before {
                  content: counter(list, lower-roman) ". ";
                  font-weight: 600;
                  margin-right: 0.5rem;
                }
              }
            }
          }
        }
        .text-lg {
          font-size: 1.1rem;
          font-weight: 600;
          margin-bottom: 1rem;
          text-align: left;
        }
        .font-semibold {
          font-weight: 600;
        }
        ul {
          margin-top: 1rem;
          counter-reset: list;
          margin-left: 15px;
          li {
            counter-increment: list;
            margin-bottom: 1rem;
            font-size: 0.9rem;
            line-height: 1.5;
            text-align: left;
            ol {
              margin-top: 1rem;
              list-style: none;
              counter-reset: list;
              margin-left: -15px !important;
              li {
                counter-increment: list;
                margin-bottom: 1rem;
                &::before {
                  content: counter(list, lower-roman) ". ";
                  font-weight: 600;
                  margin-right: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>